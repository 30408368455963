import React, { useMemo } from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, FreeMode } from "swiper";
import { StaticImage } from "gatsby-plugin-image"

import 'swiper/css';
import "swiper/css/navigation";

import Contact from "../../components/sections/contact";

const Dipndip = () => {

    const imageProps = {
        layout: "fullWidth",
        quality: 45,
        formats: ["auto", "webp", "avif"],
        placeholder: "blurred",
        className: "h-full"
    }

    // const [modal, setModal] = useState(0)
    // const [caption, setCaption] = useState("")

    const InteriorImage = ({index, item}) => {
        switch(index){
            case 1:
                return <StaticImage src={`../../images/brand/dipndip/dipndip-1.jpg`} alt={item} {...imageProps}/>
            case 2:
                return <StaticImage src={`../../images/brand/dipndip/dipndip-2.jpg`} alt={item} {...imageProps}/>
            default:
                return <StaticImage src={`../../images/brand/dipndip/dipndip-3.jpg`} alt={item} {...imageProps}/>
        }
    }

    const sliderArray = useMemo(()=> [
        "Dip n Dip Central Gurney",
        "Dip n Dip Central Gurney",
        "Dip n Dip Central Gurney",
    ], [])

    return(
        <Layout>
        <Seo title="Central@Gurney | Dip n Dip" />

        {
            typeof window !== 'undefined' && 
            <div>
                <div className="container mx-auto px-5">
                    <div className="pt-24 pb-6 flex flex-col items-center justify-center">
                        {/* <div className="w-20 mr-5">
                            <StaticImage src="../../images/brand/brand-2.png" className="transition-basic hover:opacity-50" {...imageProps}></StaticImage>
                        </div> */}
                        <h2 className="text-4xl font-semibold mb-2">Dip n Dip</h2>
                        <p className="font-light text-gray-600">10am – 10pm daily</p>
                        <p className="font-light text-gray-600">For enquiries: 011-2131 2107</p> 
                        {/* <p className="font-light text-gray-600">Daily | 8am – 10pm</p> */}
                        {/* <p className="font-light text-gray-600">For enquiries: 04-2170685</p>  */}
                    </div>
                </div>
                <Swiper
                    className="swiper-tenant"
                    modules={[Navigation, Autoplay, FreeMode]}
                    navigation={true}
                    spaceBetween={8}
                    slidesPerView={1}
                    // centeredSlides
                    breakpoints={{
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 16,
                            // centeredSlides: false,
                            initialSlide: 0
                        },
                    }}
                >
                    {sliderArray.map((item, i) => (
                        <SwiperSlide key={"tenant"+item}>
                            <div className="aspect-video bg-gray-200 relative md:my-5">
                                <InteriorImage index={i+1} item={item}/>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="container mx-auto px-5">    
                    <div className="pt-10 pb-20 xl:px-16 w-full items-center justify-center mx-auto md:w-7/12">
                        <p className="font-light leading-relaxed">          
                        Established in 2012, dipndip is an internationally acclaimed chocolate cafe with over 10 delightful locations across Malaysia. Our cafes are renowned for their decadent Belgian chocolate creations, crafted from the finest ingredients to deliver an unparalleled taste experience. At dipndip, indulgence goes beyond chocolate, offering a curated selection of savoury dishes and artisanal beverages that cater to every palate. Our mission is to transform each visit into a delightful escape, where every sip and dip brings a moment of pure joy and satisfaction. Join us and experience the art of indulgence. Sip, Dip, and Smile.
                        </p>
                    </div>
                </div>
                <Contact/>
            </div>
        }

        </Layout>
    )
}

export default Dipndip
